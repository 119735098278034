import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../auth/auth";

const RequireAuth = () => {
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  if (authState) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
